import { Navigate, createAsync, useAction } from '@solidjs/router';
import { Button, DialogContent, Dialog, Link, DialogTrigger, Container, Picture, Heading, Form } from '@troon/ui';
import { Match, Suspense, Switch, createMemo, createSignal, Show } from 'solid-js';
import { Title, Meta } from '@solidjs/meta';
import { Icon } from '@troon/icons';
import { gql, mutationAction, useMutation, Error404, ReservationUserState } from '../../../../graphql';
import { useUser } from '../../../../providers/user';
import { AuthFlow } from '../../../../partials/auth/auth';
import { getConfigValue } from '../../../../modules/config';
import { cachedQuery } from '../../../../graphql/cached-get';
import { formatDateTimeAttribute, dayTimeToDate, formatDateTime } from '../../../../modules/date-formatting';
import { createFragment } from '../../../../graphql/create-fragment';
import { Waiver } from '../../../../partials/waiver';
import type { WaiverHandler } from '../../../../partials/waiver';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function JoinReservation(props: RouteSectionProps) {
	const user = useUser();
	const [waiver, setWaiver] = createSignal<WaiverHandler>();
	const data = createAsync(
		async () => {
			const data = await getReservation({
				reservationId: props.params.reservationId!,
				shareInviteId: props.params.shareInviteId ?? '',
				invite: !!props.params.shareInviteId,
			});
			const res = data?.reservation ?? data?.reservationInvite;
			if (!res) {
				return undefined;
			}
			return createFragment(ShareReservationFragment, res);
		},
		{ deferStream: true },
	);

	const [showLogin, setShowLogin] = createSignal(false);

	const formData = createMemo(() => {
		const formData = new FormData();
		formData.set('reservationId', props.params.reservationId!);
		formData.set('shareInviteId', props.params.shareInviteId ?? '');
		return formData;
	});

	// eslint-disable-next-line solid/reactivity
	const joinAction = useMutation(join(props.params.reservationId!));
	const handleJoin = useAction(joinAction);
	const acceptAction = useMutation(accept);
	const handleAccept = useAction(acceptAction);
	const rejectAction = useMutation(reject);

	const resUser = createMemo(() => {
		return data()?.users.find((resUser) => resUser.user?.id === user()?.me.id);
	});

	return (
		<div class="relative flex min-h-screen items-center justify-center pb-24">
			<Suspense>
				<Title>
					{data()?.owner.firstName} {data()?.owner.lastName} has invited you to a tee time! | Troon
				</Title>
				<Picture
					src={data()?.facility.metadata?.hero?.url}
					alt=""
					preload
					class="absolute inset-0 -z-10 h-screen w-screen self-stretch brightness-75"
					width={1200}
					height={1200}
					sizes="(min-width 768px) 100vw, 200vw"
				/>
			</Suspense>

			<Meta
				name="og:image"
				content={`https://${getConfigValue('MAP_HOST')}/reservation/${props.params.reservationId}/join/${props.params.shareInviteId}/og.jpg`}
			/>
			<Meta name="description" content="Log in or sign up for Troon Rewards to accept this invitation." />

			<Container size="xsmall">
				<div class="mt-20 overflow-hidden rounded bg-white text-center shadow-xl">
					<Suspense fallback={<div>loading…</div>}>
						<Show when={data()}>
							{(res) => (
								<Show
									when={resUser()?.state !== ReservationUserState.Accepted}
									fallback={<Navigate href={`/reservation/${props.params.reservationId}`} />}
								>
									<div class="flex flex-col items-center gap-8">
										<Heading as="h1" size="p" class="w-full bg-brand-100 p-4 font-semibold text-brand-600">
											{res().owner.firstName} {res().owner.lastName} has invited you to play!
										</Heading>
										<div class="aspect-square size-36 rounded border border-neutral bg-white p-4">
											<Picture
												src={res().facility.metadata?.logo?.url}
												width={200}
												height={200}
												sizes="9rem"
												mode="contain"
												class="w-full"
											/>
										</div>

										<div class="flex flex-col items-center gap-3 px-6 md:px-12">
											<time
												class="text-lg font-medium text-brand"
												datetime={formatDateTimeAttribute(dayTimeToDate(res().dayTime))}
											>
												{formatDateTime(dayTimeToDate(res().dayTime))}
											</time>

											<p class="text-3xl font-semibold">{res().facility.name}</p>

											<ul class="flex flex-wrap justify-center gap-x-6 gap-y-2 text-sm text-neutral-800 md:text-base">
												<li class="flex items-center gap-1">
													<Icon name="users" /> {res().playerCount} player
													{res().playerCount > 1 ? 's' : ''}
												</li>
												<li class="flex items-center gap-1">
													<Icon name="flag" />
													<span>{res().holeCount} holes</span>
												</li>
												<Show when={res().includesCart}>
													<li class="flex items-center gap-1">
														<Icon name="golf-cart" />
														<span>Cart included</span>
													</li>
												</Show>
											</ul>
										</div>

										<div class="flex w-full flex-col items-stretch gap-4 px-6 pb-6 md:px-12 md:pb-12">
											<Switch>
												<Match when={!user() && props.params.shareInviteId}>
													<Dialog key="login-to-accept-invite" open={showLogin()} onOpenChange={setShowLogin}>
														<DialogTrigger>Log in to accept</DialogTrigger>
														<DialogContent width="fit" noPadding closeButton="text-white">
															<AuthFlow
																onComplete={() => {
																	function complete() {
																		setShowLogin(false);
																		handleJoin(formData());
																	}
																	waiver()?.confirm(complete);
																}}
															/>
														</DialogContent>
													</Dialog>
													<Button href="/" appearance="secondary" as={Link}>
														Decline
													</Button>
												</Match>

												<Match when={!resUser() && props.params.shareInviteId}>
													<Form action={joinAction} document={joinReservationMutation}>
														<input type="hidden" name="reservationId" value={props.params.reservationId!} />
														<input type="hidden" name="shareInviteId" value={props.params.shareInviteId!} />
														<Button
															type="submit"
															onClick={(e) => {
																e.preventDefault();
																function complete() {
																	handleJoin(formData());
																}
																waiver()?.confirm(complete);
															}}
														>
															Accept
														</Button>
													</Form>
													<Button href="/" appearance="secondary" as={Link}>
														Decline
													</Button>
												</Match>

												<Match when={resUser()}>
													{(resUser) => (
														<>
															<Form action={acceptAction} document={acceptInvitationMutation}>
																<input type="hidden" name="reservationUserId" value={resUser().id} />
																<Button
																	type="submit"
																	onClick={(e) => {
																		e.preventDefault();
																		const data = new FormData();
																		data.set('reservationUserId', resUser().id);
																		function complete() {
																			handleAccept(data);
																		}
																		waiver()?.confirm(complete);
																	}}
																>
																	Accept
																</Button>
															</Form>
															<Form action={rejectAction} document={rejectInvitationMutation}>
																<input type="hidden" name="reservationUserId" value={resUser().id} />
																<Button type="submit" appearance="secondary">
																	Decline
																</Button>
															</Form>
														</>
													)}
												</Match>
											</Switch>
										</div>
									</div>
								</Show>
							)}
						</Show>
					</Suspense>
				</div>
			</Container>
			<Waiver ref={setWaiver} />
		</div>
	);
}

const query = gql(`query reservationInvite($reservationId: String!, $shareInviteId: String!, $invite: Boolean!) {
	reservation(id: $reservationId) @skip(if: $invite) {
		...ShareReservation
  }
  reservationInvite(reservationId: $reservationId, shareInviteId: $shareInviteId) @include(if: $invite) {
		...ShareReservation
  }
}`);

const ShareReservationFragment = gql(`fragment ShareReservation on Reservation {
	holeCount
	includesCart
	playerCount
	owner {
		firstName
		lastName
	}
	users {
		id
		state
		user { id }
	}
	dayTime { day { year, month, day }, time { hour, minute } }
	facility {
		name
		metadata {
     	logo { url }
     	hero { url }
		}
	}
}`);

const getReservation = cachedQuery(query, {
	onError: (error) => {
		if (error.graphQLErrors[0]?.message.toLowerCase().includes('not found')) {
			throw new Error404(error.graphQLErrors[0]);
		}
	},
});

const joinReservationMutation = gql(`mutation joinReservation($reservationId: String!, $shareInviteId: String!) {
	joinReservation(reservationId: $reservationId, shareInviteId: $shareInviteId) { id }
}`);

const join = (reservationId: string) =>
	mutationAction(joinReservationMutation, {
		revalidates: ['home', 'allReservations'],
		redirect: `/reservation/${reservationId}`,
		toast: 'You’re all set to tee off!',
		track: {
			event: 'acceptInvitation',
			data: { reservationId },
		},
	});

const acceptInvitationMutation = gql(`mutation acceptReservationInvite($reservationUserId: String!) {
	acceptReservationInvite(reservationUserId: $reservationUserId) { id }
}`);

const accept = mutationAction(acceptInvitationMutation, {
	redirect: (res) => `/reservation/${res?.acceptReservationInvite.id}`,
	revalidates: ['reservationInformation'],
	track: {
		event: 'acceptInvitation',
		transform: (data) => ({ reservationUserId: data.get('reservationUserId') as string }),
	},
});

const rejectInvitationMutation = gql(`mutation rejectReservationInvite($reservationUserId: String!) {
	rejectReservationInvite(reservationUserId: $reservationUserId) { ok }
}`);

const reject = mutationAction(rejectInvitationMutation, {
	redirect: '/',
	toast: 'You have declined this invitation.',
	track: {
		event: 'rejectInvitation',
		transform: (data) => ({ reservationId: data.get('reservationId') as string }),
	},
});

export const route = { info: { nav: { appearance: 'minimal', hero: true } } } satisfies RouteDefinition;
